/* This does not import all ant styles - just the general ones (mostly animation related) */
/* @import '~antd/dist/antd.css'; */
/* We have instead decided to just globally import all ant
 * styles that we use everywhere  - this is because otherwise
 * import order/dependency management is a _nightmare_. 
 * Some css classes that are only used in a few places are placed
 * at the bottom and commented out - for reference doing this saves
 * around 50% of the default 66.6kB payload at time of writing */
@import '~antd/lib/style/index.css';
@import '~antd/lib/input/style/index.css';
@import '~antd/lib/button/style/index.css';
@import '~antd/lib/checkbox/style/index.css';
@import '~antd/lib/collapse/style/index.css';
@import '~antd/lib/divider/style/index.css';
@import '~antd/lib/dropdown/style/index.css';
@import '~antd/lib/form/style/index.css';
@import '~antd/lib/menu/style/index.css';
@import '~antd/lib/message/style/index.css';
@import '~antd/lib/modal/style/index.css';
@import '~antd/lib/popover/style/index.css';
@import '~antd/lib/radio/style/index.css';
@import '~antd/lib/select/style/index.css';
@import '~antd/lib/tabs/style/index.css';
@import '~antd/lib/tag/style/index.css';
@import '~antd/lib/tooltip/style/index.css';
@import '~antd/lib/alert/style/index.css';
@import '~antd/lib/tree-select/style/index.css';
/* This is used in only once place*/
/* @import '~antd/lib/card/style/index.css'; */
/* I believe this is unised */
@import '~antd/lib/input-number/style/index.css';

#root {
  height: 100%;
}

body {
  /*Having hidden overflow breaks landing page, but we need it 
  for scrolling the main diagram view, so we dynamically add a second class body--main-page*/
  overflow: hidden;
  touch-action: none;
  margin: 0;
  padding: 0;
  /*font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;*/
  font-family: 'Nunito Sans', sans-serif;
  line-height: initial;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.28571429em;
}

p:last-child {
  margin-bottom: 0;
}
p {
  margin: 0 0 1em;
  line-height: 1.4285em;
}

input,
button {
  font-family: 'Nunito Sans', sans-serif;
}

code::selection,
code *::selection {
  color: inherit;
}

hr {
  margin-top: 0.5em;

  /*This second one is only needed for Bootstrap library unoverriding*/
  margin-bottom: 0.5em;
}

/* Override how much we indent in tree selects */
.ant-select-tree-indent-unit {
  width: 8px !important;
}

/* Add our own "Error" class to ant inputs - based on their styling, just different api */
.ant-input.error,
.ant-input-affix-wrapper.error {
  border-color: #ff4d4f;
}
.ant-input.error:focus,
.ant-input-affix-wrapper-focused.error {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.HelpContentDefault.HelpContentHaloEffect {
  box-shadow: 0 0px 15px 0 rgba(79, 127, 255, 1),
    0 0px 15px 0 rgba(0, 0, 0, 0.19) !important; /*Important helps draggable nodes */
}

.HelpContentDefault {
  transition: all 0.5s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  /*box-shadow: 0 0px 15px 0 rgba(79, 127, 255, 0), 0 0px 15px 0 rgba(0, 0, 0, 0);*/
}

/* Diagram related styling */

.diagram-layer {
  height: 100%;
  position: relative;
}

.srd-diagram {
  height: 100%;
}

.ff-srd-background-layer {
  overflow: visible !important;
  /*background: url('./assets/tiny_grid.png');*/
  background: url('./assets/grid_background_20x20_half_width.svg');
}

.srd-default-link__label {
  overflow: visible;
}

.node-title {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

/* Put selected nodes on top */
.srd-node--selected {
  z-index: 2;
}

.srd-node--selected .form-node {
  border-style: solid;
}

.form-node {
  background-clip: padding-box;
}

/* Dragging classes */
.smooth-dnd-ghost {
  cursor: grabbing;
}

.showOnDragging {
  display: none;
}

.smooth-dnd-ghost .showOnDragging {
  display: block;
}

.draggingToolbarItem {
  transition: all 1s ease;
  opacity: 1;
  cursor: grabbing;
  background-color: #999;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.3);
}

.anticon-spin {
  animation: loadingCircle 1s infinite linear;
}

.emptyEditingElement {
  text-align: center;
}

/*.HelpButton {
  color: white;
  position: absolute;
  bottom: 50px;
  width: 100%;
  border: 3px;
  border-color: #808080;
  border-style: solid;
  background: none;
  border-radius: 50px;
  padding: 3px;
  font-size: 20px;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.HelpButton:hover {
  color: black;
  background: gray;
}
*/
@keyframes loadingCircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Scroll bar look and feel */
body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px !important;
  height: 10px;
}

body ::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.25);
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}
body ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0;
}
